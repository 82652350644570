import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/4uNiSSHEszE">
    <SEO title="Persevere for the Promise - Hebrews" />
  </Layout>
)

export default SermonPost
